import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"

const reducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return initialState
  }
  if (action.type === "SET_STATE") {
    return { ...state, [action.data.prop]: action.data.val }
  }
  if (action.type === "LOADING_START") {
    console.log("load 'em up")
    return { ...state, isLoading: true }
  }
  if (action.type === "LOADING_START_SCREENER_DATA") {
    console.log("load 'em up")
    return {
      ...state,
      isLoadingVideoCreatedGrouped: true,
      isLoadingVideoUsers: true,
    }
  }
  if (action.type === "LOADING_START_SCREENER_DATA_MINI") {
    console.log("load 'em up")
    return {
      ...state,
      isLoadingVideoMini: true,
    }
  }
  if (action.type === "LOADING_END") {
    return {
      ...state,
      isLoading: false,
      isLoadingVideoCreatedGrouped: false,
      isLoadingVideoUsers: false,
      isLoadingVideoMini: false,
    }
  }
  if (action.type === "LOADING_START_TEMPLATE_CHECK") {
    console.log("load 'em up")
    return { ...state, isLoadingTemplateCheck: true }
  }
  if (action.type === "LOADING_SENDGROUP_START") {
    console.log("load 'em up")
    return { ...state, isLoadingSendGroup: true }
  }
  if (action.type === "LOADING_SUBMISSION_START") {
    console.log("load 'em up")
    return { ...state, loadingSubmission: true }
  }
  if (action.type === "LOADING_MINI_START") {
    console.log("load 'em up")
    return { ...state, isLoadingMini: { [action.data.sessionid]: true } }
  }
  if (action.type === "LOADING_MODERATESUBMIT_START") {
    console.log("load 'em up")
    return { ...state, isLoadingModerate: { [action.data.sessionid]: true } }
  }
  if (action.type === "CAMPAIGNS_REPLACE") {
    console.log("campaigns-replace")
    return { ...state, isLoading: false, campaign: action.data }
  }
  if (action.type === "AGREEMENT_REPLACE") {
    console.log("agreement-replace")
    return { ...state, isLoading: false, agreements: action.data }
  }
  if (action.type === "CAMPAIGNS_TOACTIVATE_REPLACE") {
    console.log("campaigns-toactivate-replace")
    return { ...state, isLoading: false, campaignactivate: action.data }
  }
  if (action.type === "CAMPAIGN_ACTIVATE_REPLACE") {
    console.log("CAMPAIGN_ACTIVATE_REPLACE")
    return {
      ...state,
      loadingSubmission: false,
      submitted: true,
      campaignactivated: action.data,
    }
  }
  if (action.type === "VIDEOS_CREATED_GROUPED_REPLACE") {
    console.log("videos-GROUPED-replace")
    return {
      ...state,
      isLoadingVideoCreatedGrouped: false,
      isLoadingVideoMini: false,
      videosgrouped: action.data,
    }
  }
  if (action.type === "VIDEOS_UPDATE_SELECTED_OPTIONS") {
    console.log("**VIDEOS_UPDATE_SELECTED_OPTIONS")
    console.log("state")
    console.log(state)
    console.log("action.data")
    console.log(action.data)

    // const newVideos = state.videosgrouped[action.data.templateid].selected.map(item => {
    //   console.log("***item")
    //   console.log(item)
    //   // if (item.selected === action.data.id) {
    //   return { ...item, [name]: val }
    //   // }
    //   // return item
    // })

    return {
      ...state,
      // isLoadingMini: { [action.data.sessionid]: false },
      videosgrouped: {
        ...state.videosgrouped,
        [action.data.templateid]: {
          ...state.videosgrouped[action.data.templateid],
          [action.data.group]: {
            ...state.videosgrouped[action.data.templateid][action.data.group],
            [action.data.name]: action.data.val,
          },
        },
      },
    }
  }
  if (action.type === "CAMPAIGNFILTER_METADATA_REPLACE") {
    console.log("CAMPAIGNFILTER_METADATA_REPLACE")

    console.log("action.data")
    console.log(action.data)
    return {
      ...state,
      // isLoadingMini: { [action.data.sessionid]: false },
      filterdata: {
        ...state.filterdata,
        [action.data.templateid]: {
          ...state.filterdata[action.data.templateid],
          data: action.data.metadata,
        },
      },
    }
  }

  if (action.type === "VIDEOS_USERS_REPLACE") {
    console.log("videos-RAW-replace")
    return {
      ...state,
      isLoadingVideoUsers: false,
      isLoadingVideoMini: false,
      users: action.data,
    }
  }
  if (action.type === "SOCIAL_GROUPED_REPLACE") {
    console.log("SOCIAL-GROUPED-replace")
    return { ...state, isLoading: false, social: action.data }
  }
  if (action.type === "REVIEWS_REPLACE") {
    console.log("REVIEWS_REPLACE-replace")
    console.log({ ...state, isLoading: false, reviews: action.data })
    return { ...state, isLoading: false, reviews: action.data }
  }
  if (action.type === "TEMPLATE_CREATE_KEYWORD_CHECK") {
    console.log("TEMPLATE_CREATE_KEYWORD_CHECK")
    console.log(action.data)
    return {
      ...state,
      isLoadingTemplateCheck: false,
      templatekeyword: action.data,
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_FORM") {
    console.log("TEMPLATE_CREATE_UPDATE_FORM")
    console.log("action.data")
    console.log(action.data)
    console.log("action.mode")
    console.log(action.mode)
    if (action.mode === "survey" || action.mode === "criteria") {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          [`${action.mode}counter`]:
            state.templatecreate[`${action.mode}counter`] + 1,
          [action.mode]: [
            ...state.templatecreate[action.mode],
            action.data.val,
          ],
        },
      }
    } else {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          [action.data.prop]: action.data.val,
        },
      }
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_FORM_FIELD") {
    console.log("TEMPLATE_CREATE_UPDATE_FORM_FIELD")
    console.log("action.data")
    console.log(action.data)
    console.log("action.mode")
    console.log(action.mode)

    return {
      ...state,
      isLoading: false,
      templatecreate: {
        ...state.templatecreate,
        [action.data.group]: state.templatecreate[action.data.group].map(
          (item, i) =>
            i === action.data.marker
              ? {
                  ...item,
                  [action.data.prop]: action.data.val,
                }
              : item
        ),
      },
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_FORM_FIELD_OBJ") {
    console.log("TEMPLATE_CREATE_UPDATE_FORM_FIELD_OBJ")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      templatecreate: {
        ...state.templatecreate,
        [action.data.group]: {
          ...state.templatecreate[action.data.group],
          [action.data.prop]: action.data.val,
        },
      },
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_FORM_FIELD_LANG") {
    console.log("TEMPLATE_CREATE_UPDATE_FORM_FIELD_LANG")
    console.log("action.data")
    console.log(action.data)
    console.log("action.mode")
    console.log(action.mode)

    return {
      ...state,
      isLoading: false,
      templatecreate: {
        ...state.templatecreate,
        survey: state.templatecreate.survey.map((item, i) =>
          i === action.data.marker
            ? {
                ...item,
                // [action.data.prop]: item[action.data.prop],
                [action.data.prop]: {
                  ...item[action.data.prop],
                  [action.data.lang]: action.data.val,
                },
              }
            : item
        ),
      },
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_DELIVER_LANG") {
    console.log("TEMPLATE_CREATE_UPDATE_DELIVER_LANG")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      templatecreate: {
        ...state.templatecreate,
        criteria: state.templatecreate.criteria.map((item, i) =>
          i === action.data.marker
            ? {
                ...item,
                deliver: item.deliver.map((subitem, j) =>
                  subitem.lang === action.data.lang
                    ? {
                        ...subitem,
                        [action.data.prop]: action.data.val,
                      }
                    : subitem
                ),
              }
            : item
        ),
      },
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_PROMPTS_LANG") {
    console.log("TEMPLATE_CREATE_UPDATE_PROMPTS_LANG")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      templatecreate: {
        ...state.templatecreate,
        prompts: state.templatecreate.prompts.map((item, i) =>
          item.lang === action.data.lang
            ? {
                ...item,
                data: item.data.map((subitem, j) =>
                  j === action.data.marker
                    ? { ...subitem, [action.data.prop]: action.data.val }
                    : subitem
                ),
              }
            : item
        ),
      },
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_LANDING_LANG") {
    console.log("TEMPLATE_CREATE_UPDATE_LANDING_LANG")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      templatecreate: {
        ...state.templatecreate,
        landing: {
          ...state.templatecreate.landing,
          landingdata: state.templatecreate.landing.landingdata.map((item, i) =>
            item.lang === action.data.lang
              ? {
                  ...item,
                  [action.data.prop]: action.data.val,
                }
              : item
          ),
        },
      },
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_FORM_OPTION") {
    console.log("TEMPLATE_CREATE_UPDATE_FORM_OPTION")
    console.log("action.data")
    console.log(action.data)
    console.log("action.mode")
    console.log(action.mode)

    if (action.mode === "add" && action.data.formoption === "choices") {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          survey: state.templatecreate.survey.map((item, i) =>
            i === action.data.marker
              ? {
                  ...item,
                  choices: [
                    ...item.choices,
                    {
                      // ...item.choices,
                      value: action.data.val,
                      text: {
                        // ...item.choices.text,
                        default: action.data.val,
                      },
                    },
                  ],
                }
              : item
          ),
        },
      }
    } else if (
      action.mode === "update" &&
      action.data.formoption === "choices"
    ) {
      console.log("update - action.data")
      console.log(action.data)
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          survey: state.templatecreate.survey.map((item, i) =>
            i === action.data.marker
              ? {
                  ...item,
                  choices: item.choices.map((choicegroup, j) =>
                    j === action.data.pos
                      ? {
                          ...choicegroup,
                          value: action.data.val,
                          text: {
                            // ...item.choicegroup.text,
                            default: action.data.val,
                          },
                        }
                      : choicegroup
                  ),
                }
              : item
          ),
        },
      }
    } else if (
      action.mode === "delete" &&
      action.data.formoption === "choices"
    ) {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          survey: state.templatecreate.survey.map((item, i) =>
            i === action.data.marker
              ? {
                  ...item,
                  choices: item.choices.filter(
                    (choicegroup, j) => j !== action.data.pos
                  ),
                }
              : item
          ),
        },
      }
    } else if (
      action.mode === "add" &&
      action.data.formoption === "criteriaoption"
    ) {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          criteria: state.templatecreate.criteria.map((item, i) =>
            i === action.data.marker
              ? {
                  ...item,
                  test: {
                    ...item.test,
                    // ...item.choices,
                    // value: action.data.val,
                    data: [
                      ...item.test.data,
                      {
                        dataname: "",
                        dataval: "",
                      },
                    ],
                  },
                }
              : item
          ),
        },
      }
    } else if (
      action.mode === "delete" &&
      action.data.formoption === "criteriaoption"
    ) {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          criteria: state.templatecreate.criteria.map((item, i) =>
            i === action.data.marker
              ? {
                  ...item,
                  test: {
                    ...item.test,
                    data: item.test.data.filter(
                      (datagroup, j) => j !== action.data.pos
                    ),
                  },
                }
              : item
          ),
        },
      }
    } else if (
      action.mode === "update" &&
      action.data.formoption === "criteriaoption"
    ) {
      console.log("update - action.data - criteriaoption")
      console.log(action.data)
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          criteria: state.templatecreate.criteria.map((item, i) =>
            i === action.data.marker
              ? {
                  ...item,
                  test: {
                    ...item.test,
                    data: item.test.data.map((datagroup, j) =>
                      j === action.data.pos
                        ? {
                            ...datagroup,
                            [action.data.prop]: action.data.val,
                          }
                        : datagroup
                    ),
                  },
                }
              : item
          ),
        },
      }
    } else if (
      action.mode === "add" &&
      action.data.formoption === "pushcampaign"
    ) {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          screener: {
            ...state.templatecreate.screener,
            pushcampaign: [
              ...state.templatecreate.screener.pushcampaign,
              action.data.val,
            ],
          },
        },
      }
    } else if (
      action.mode === "delete" &&
      action.data.formoption === "pushcampaign"
    ) {
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          screener: {
            ...state.templatecreate.screener,
            pushcampaign: state.templatecreate.screener.pushcampaign.filter(
              (item, j) => item !== action.data.val
            ),
          },
        },
      }
    } else if (
      action.mode === "update" &&
      action.data.formoption === "pushcampaign"
    ) {
      console.log("update - action.data - pushcampaign")
      console.log(action.data)
      return {
        ...state,
        isLoading: false,
        templatecreate: {
          ...state.templatecreate,
          screener: {
            ...state.templatecreate.screener,
            pushcampaign: state.templatecreate.screener.pushcampaign.map(
              (item, i) => (i === action.data.marker ? action.data.val : item)
            ),
          },
        },
      }
    }
  }
  if (action.type === "TEMPLATE_CREATE_REMOVE_ITEM") {
    console.log("TEMPLATE_CREATE_REMOVE_ITEM")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      templatecreate: {
        ...state.templatecreate,
        [action.data.prop]: state.templatecreate[action.data.prop].filter(
          (item, index) => index !== action.data.pos
        ),
      },
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_POSITION") {
    console.log("TEMPLATE_CREATE_UPDATE_POSITION")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      templateposition: action.data,
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_START") {
    console.log("TEMPLATE_CREATE_UPDATE_START")

    return {
      ...state,
      isLoadingTemplateCreate: true,
      templateposition: 0,
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_COMPLETE") {
    console.log("TEMPLATE_CREATE_UPDATE_COMPLETE")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoadingTemplateCreate: false,
      templatecreate: initialTemplateCreate,
      templateposition: 1,
      templatekeyword: "initial",
      templatecomplete: true,
    }
  }
  if (action.type === "VIDEO_TAGS_UPDATE") {
    let tags = []
    if (action.data && action.data.tags) {
      tags = action.data.tags
    }
    const newReviews = state.reviews[action.data.templateid].map((item) => {
      console.log("***item")
      console.log(item)
      if (item.id === action.data.id) {
        return { ...item, tags: tags }
      }
      return item
    })

    console.log(">>pre return")
    console.log(state)
    console.log(">>newReviews")
    console.log(newReviews)
    return {
      ...state,
      isLoadingMini: { [action.data.sessionid]: false },
      reviews: { [action.data.templateid]: newReviews },
    }
  }

  if (action.type === "VIDEOS_TOMODERATE_REPLACE") {
    console.log("VIDEOS_TOMODERATE_REPLACE")
    return { ...state, isLoading: false, moderatevideos: action.data }
  }
  if (action.type === "VIDEOS_AFTERMODERATE_REPLACE") {
    console.log("VIDEOS_AFTERMODERATE_REPLACE")
    return {
      ...state,
      isLoadingModerate: { [action.data.sessionid]: false },
      moderatevideos: state.moderatevideos.filter(
        (item) => item.id !== action.data.sessionid
      ),
    }
  }

  if (action.type === "USER_LOGIN") {
    console.log("createStore - USER_LOGIN - action.data")
    console.log(action.data)
    return { ...state, isLoading: false, userlogin: action.data, usererror: {} }
  }

  if (action.type === "USER_LOGIN_ERROR") {
    return { ...state, isLoading: false, usererror: action.data }
  }
  if (action.type === "COMPANY_DETAIL_REPLACE") {
    console.log("createStore - COMPANY_DETAIL_REPLACE - action.data")
    console.log(action.data)
    return { ...state, isLoading: false, company: action.data, usererror: {} }
  }
  if (action.type === "SENDGROUP_REPLACE") {
    console.log("sendgroup-replace")
    return { ...state, isLoadingSendGroup: false, sendgroup: action.data }
  }

  if (action.type === "SENDGROUP_UPDATE_DIRECT_OBJ") {
    console.log("SENDGROUP_UPDATE_DIRECT_OBJ")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      sendgroupcreate: {
        ...state.sendgroupcreate,
        [action.data.group]: {
          ...state.sendgroupcreate[action.data.group],
          [action.data.prop]: action.data.val,
        },
      },
    }
  }

  if (action.type === "SENDGROUP_UPDATE_FORM_OPTION") {
    console.log("SENDGROUP_UPDATE_FORM_OPTION")
    console.log("action.data")
    console.log(action.data)
    console.log("action.mode")
    console.log(action.mode)

    if (action.mode === "add" && action.data.formoption === "users") {
      return {
        ...state,
        isLoading: false,
        sendgroupcreate: {
          ...state.sendgroupcreate,
          users: [
            ...state.sendgroupcreate.users,
            {
              fname: "",
              lname: "",
              mobile: { format: "", provided: "", valid: false },
              send: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
              },
            },
          ],
        },
      }
    } else if (action.mode === "update" && action.data.formoption === "users") {
      console.log("SENDGROUP_UPDATE_FORM_OPTION - update - action.data")
      console.log(action.data)
      return {
        ...state,
        isLoading: false,
        sendgroupcreate: {
          ...state.sendgroupcreate,
          users: state.sendgroupcreate.users.map((user, i) =>
            i === action.data.marker
              ? {
                  ...user,
                  [action.data.prop]: action.data.val,
                }
              : user
          ),
        },
      }
    } else if (action.mode === "delete" && action.data.formoption === "users") {
      return {
        ...state,
        isLoading: false,
        sendgroupcreate: {
          ...state.sendgroupcreate,
          users: state.sendgroupcreate.users.filter(
            (user, i) => i !== action.data.marker
          ),
        },
      }
    }
  }

  if (action.type === "SENDGROUP_UPDATE_FORM_DAY") {
    console.log("SENDGROUP_UPDATE_FORM_DAY")
    console.log("action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      sendgroupcreate: {
        ...state.sendgroupcreate,
        users: state.sendgroupcreate.users.map((user, i) =>
          i === action.data.marker
            ? {
                ...user,
                send: {
                  ...user.send,
                  [action.data.prop]: action.data.val,
                },
              }
            : user
        ),
      },
    }
  }

  if (action.type === "SENDGROUP_CREATE_UPDATE_START") {
    console.log("SENDGROUP_CREATE_UPDATE_START")

    return {
      ...state,
      isLoadingSendGroupCreate: true,
    }
  }
  if (action.type === "SENDGROUP_CREATE_UPDATE_COMPLETE") {
    console.log("SENDGROUP_CREATE_UPDATE_COMPLETE")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoadingSendGroupCreate: false,
      sendgroupcreate: initialSendGroupCreate,
      sendgroupcomplete: true,
    }
  }
  if (action.type === "SENDGROUP_EDIT_COPY") {
    console.log("SENDGROUP_EDIT_COPY")
    console.log("action.data")
    console.log(action.data)

    if (action.mode && action.mode === "initial") {
      return {
        ...state,
        isLoadingSendGroupCreate: false,
        sendgroupcreate: initialSendGroupCreate,
        sendgroupcomplete: false,
      }
    } else {
      return {
        ...state,
        isLoadingSendGroupCreate: false,
        sendgroupcreate: {
          ...state.sendgroupcreate,
          deliver: action.data.deliver,
          sendgroup: action.data.sendgroup,
          users: action.data.users,
          templates: action.data.templates,
          error: initialSendGroupCreateError,
        },
      }
    }
  }
  if (action.type === "SCREENER_EDIT_COPY") {
    console.log("SCREENER_EDIT_COPY")
    console.log("action.data")
    console.log(action.data)

    if (action.mode && action.mode === "initial") {
      return {
        ...state,
        isLoadingSendGroupCreate: false,
        isLoadingTemplateCreate: false,
        templatecreate: initialTemplateCreate,
        templatecomplete: false,
        templateposition: 1,
      }
    } else {
      return {
        ...state,
        isLoadingSendGroupCreate: false,
        isLoadingTemplateCreate: false,
        templateposition: 1,
        templatecreate: {
          ...state.templatecreate,
          inputmode: "edit",
          criteria: action.data.criteria,
          landing: action.data.landing,
          prompts: action.data.prompts,
          screener: {
            ...action.data.screener, //copying from action.data instead of state here on purpose
            keywordorig: action.data.screener.keyword,
            pushcampaignorig: action.data.screener.pushcampaign,
          },
          survey: action.data.survey,
          surveycounter: action.data.survey.length,
          error: initialTemplateCreateError,
        },
      }
    }
  }

  return state
}

const initialTemplateCreateError = {
  screenername: false,
  screenerkeyword: false,
  screenerpushcampaignlength: false,
  screenerpushcampaignitems: [],
  screenerpushcampaignitemserror: false,
  prompttext: false,
  screenerlogo: false,
  imagewidth: false,
  imagesize: false,
  imageextension: false,
  uploadtext: false,
  uploadsubtext: false,
  surveylength: false,
  surveyitems: [],
  surveyerror: false,
}

const initialTemplateCreate = {
  inputmode: "create",
  survey: [],
  surveycounter: 1,
  criteria: [],
  criteriacounter: 1,
  landing: {
    landingdata: [{ lang: "en", uploadtext: "", uploadsubtext: "" }],
  },
  prompts: [{ lang: "en", data: [{ text: "" }] }],
  screener: {
    mode: "response",
    keyword: "",
    keywordorig: "",
    pushcampaign: [],
    pushcampaignorig: [],
  },
  error: initialTemplateCreateError,
}

const initialSendGroupCreateError = {
  sendgroupname: false,
  sendgroupuserslength: false,
  sendgroupusersitems: [],
  sendgroupuserserror: false,
}

const initialSendGroupCreate = {
  users: [],
  deliver: {
    calchour: 9,
    meridian: "am",
    rawhour: 9,
    timezone: "US/Mountain",
    utchrs: 16,
    utcmins: 960,
  },
  sendgroup: { name: "" },
  error: initialSendGroupCreateError,
}

const initialState = {
  isLoading: false,
  isLoadingSendGroup: false,
  isLoadingTemplateCheck: false,
  isLoadingTemplateCreate: false,
  isLoadingSendGroupCreate: false,
  isLoadingVideoCreatedGrouped: false,
  isLoadingVideoUsers: false,
  isLoadingVideoMini: false,
  filterSort: "asc",
  filterStar: false,
  filterdata: {},
  templatekeyword: "initial",
  templateposition: 1,
  templatecreate: initialTemplateCreate,
  templatecomplete: false,
  sendgroupcreate: initialSendGroupCreate,
  sendgroupcomplete: false,
  userlogin: {},
  company: {},
}

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk))

export default createStore
